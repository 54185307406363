export const loadState = (): object | undefined => {
  try {
    const serializedState = sessionStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState) as object;
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: unknown): void => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
