import {AnimatePresence, motion} from "framer-motion";
import React, {ReactElement, useState} from "react";

import {transitionVariants} from "../../constants/constants";
import {DeadlineIndicator} from "../DeadlineIndicator";
import {OngoingConnectivityAdvisory} from "../OngoingConnectivity/OngoingConnectivityAdvisory";
import {SubuserChoiceStatus} from "../OngoingConnectivity/OngoingConnectivityCommon";
import {OngoingConnectivityStart} from "../OngoingConnectivity/OngoingConnectivityStart";

type SyncResultsAndUserDecisionProps = Readonly<{
  deadline?: string | null;
}>;

export interface OngoingConnectivityChoiceProps {
  setSubuserChoice: (event: SubuserChoiceStatus) => void;
  subuserChoice: SubuserChoiceStatus;
  deadlineIndicator: ReactElement | null;
  disabled: boolean;
}

export default function SyncResultsAndSubUserDecision(props: SyncResultsAndUserDecisionProps): ReactElement {
  const {deadline} = props;
  const [subuserChoice, setSubuserChoice] = useState<SubuserChoiceStatus>(SubuserChoiceStatus.Start);

  let decisionScreenContent = null;

  const [deadlineStart] = useState<Date>(new Date());
  const [expired, setExpired] = useState<boolean>(false);
  const deadlineIndicator = deadline ? (
    <>
      <DeadlineIndicator start={deadlineStart} deadline={deadline} onExpiration={() => setExpired(true)} />
    </>
  ) : null;

  switch (subuserChoice) {
    // "Ongoing connectivity"
    case SubuserChoiceStatus.Start: {
      decisionScreenContent = (
        <OngoingConnectivityStart
          setSubuserChoice={setSubuserChoice}
          subuserChoice={subuserChoice}
          deadlineIndicator={deadlineIndicator}
          disabled={expired}
        />
      );
      break;
    }
    // "Are you sure"
    case SubuserChoiceStatus.Advisory: {
      decisionScreenContent = (
        <OngoingConnectivityAdvisory
          setSubuserChoice={setSubuserChoice}
          subuserChoice={subuserChoice}
          deadlineIndicator={deadlineIndicator}
          disabled={expired}
        />
      );
      break;
    }
    default: {
      decisionScreenContent = null;
      break;
    }
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={subuserChoice}
        className="flex h-full w-full"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={transitionVariants}>
        {decisionScreenContent}
      </motion.div>
    </AnimatePresence>
  );
}
