import "../index.css";

import MarkdownToJSX from "markdown-to-jsx";
import React, {ReactElement, ReactNode} from "react";

import {MarkdownType} from "../interfaces";

interface MarkdownProps {
  markdown: MarkdownType;
}

const h1 = ({children}: {children: ReactNode}) => (
  <h1 className="text-2xl md:text-4xl font-semibold text-navy-500">{children}</h1>
);
const h2 = ({children}: {children: ReactNode}) => (
  <h2 className="mt-2 text-2xl font-semibold tracking-tight text-gray-600 sm:text-3xl">{children}</h2>
);
const h3 = ({children}: {children: ReactNode}) => (
  <h3 className="mt-2 text-xl font-light tracking-tight text-gray-900 sm:text-2xl">{children}</h3>
);
const p = ({children}: {children: ReactNode}) => <p className="text-md md:text-lg ">{children}</p>;
const div = ({children}: {children: ReactNode}) => <div className="text-lg">{children}</div>;
const span = ({children}: {children: ReactNode}) => <span className="text-lg">{children}</span>;
const ol = ({children}: {children: ReactNode}) => <ol className="ml-6 text-lg list-decimal">{children}</ol>;
const ul = ({children}: {children: ReactNode}) => <ul className="ml-6 text-sm md:text-lg list-disc">{children}</ul>;
const li = ({children}: {children: ReactNode}) => <li className="mt-2 md:mt-6">{children}</li>;
const td = ({children}: {children: ReactNode}) => <td className="text-lg pr-4 whitespace-nowrap">{children}</td>;

export default function Markdown({markdown}: MarkdownProps): ReactElement {
  return (
    <MarkdownToJSX
      options={{
        namedCodesToUnicode: {
          ldquot: "\u201C",
          rdquot: "\u201D",
        },
        overrides: {
          p: p,
          div: div,
          span: span,
          h1: h1,
          h2: h2,
          h3: h3,
          ol: ol,
          ul: ul,
          li: li,
          td: td,
        },
      }}>
      {markdown}
    </MarkdownToJSX>
  );
}
