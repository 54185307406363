import React, {ReactElement, ReactNode} from "react";

export interface ContentBodyProps {
  children: ReactNode;
}

export default function ContentBody({children}: ContentBodyProps): ReactElement {
  return (
    <div className="max-md:grow w-full max-md:min-h-0 max-md:overflow-y-auto">
      <div className="flex flex-col h-full w-full">{children}</div>
    </div>
  );
}
