import "../index.css";

import React, {ReactElement} from "react";

type ContentHeaderProps = Readonly<{
  title: string;
}>;

export default function ContentHeader({title}: ContentHeaderProps): ReactElement {
  return <div className="text-2xl md:text-4xl font-semibold text-navy-500">{title}</div>;
}
