import React from "react";

import PlatformIcon from "../components/PlatformIcon";
import {Account, ServiceLine} from "../interfaces";

function ConnectedServiceLine({serviceLine}: {serviceLine: ServiceLine}) {
  const getAccountRepr = (idx: number, acct: Account): string =>
    [acct.displayName, acct.accountMask && `****${acct.accountMask}`].filter((e) => e).join(" ") || `Account ${idx}`;
  return (
    <div className="flex items-center justify-between p-2 md:p-4 text-gray-700 border-1 border-navy-100">
      <div className="flex flex-row items-center gap-x-4">
        <div className="self-start mt-1">
          <PlatformIcon icon={serviceLine.platform.icon} />
        </div>
        <div className="overflow-hidden">
          <div className="font-bold text-lg mb-1 md:mb-2">{serviceLine.platform.name}</div>
          {serviceLine.accounts.length > 0 && <div className="font-light text-xs mb-1">Accounts</div>}
          {serviceLine.accounts.map((a, i) => (
            <div key={i} className="font-light text-xs md:text-md">
              {getAccountRepr(i, a)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ConnectedServiceLine;
