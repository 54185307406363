import {debounce} from "lodash";
import React, {Suspense, useCallback, useState} from "react";
import {suspend} from "suspend-react";

import {RequestError} from "../api/apiHandler";
import {getPlatforms} from "../api/connectApi";
import PlatformIcon from "../components/PlatformIcon";
import useAsyncError from "../hooks/useAsyncError";
import {Platform} from "../interfaces";
import {getClientConfig} from "../store/clientConfigSlice";
import {useAppSelector} from "../store/hooks";
import {FormTextField} from "./FormTextField";

interface PlatformSearchListProps {
  searchText: string;
  onSelect: (p: Platform) => void;
}

function DummyPlatform() {
  return (
    <div className="animate-pulse flex items-center justify-between p-4 border-1 border-navy-100">
      <div className="flex items-center overflow-hidden gap-x-4">
        <div className="h-12 w-12 bg-navy-100 rounded-full" />
        <div className="overflow-hidden">
          <div className="w-72 h-2 bg-navy-100 rounded-full mb-2" />
          <div className="w-52 h-2 bg-navy-100 rounded-full" />
        </div>
      </div>
    </div>
  );
}

function PlatformSearchListFallback() {
  return (
    <div className="mt-6 flex flex-col gap-y-2">
      <DummyPlatform />
      <DummyPlatform />
      <DummyPlatform />
    </div>
  );
}

function PlatformSearchList({searchText, onSelect}: PlatformSearchListProps) {
  const [, setRequestError] = useState<RequestError | null>(null);
  const asyncThrow = useAsyncError();
  const {defaultPlatformSlugs: clientDefaultPlatformSlugs} = useAppSelector(getClientConfig);
  const defaultPlatformSlugs = ["local", "sandbox"].includes(process.env.REACT_APP_HOST_ENV as string)
    ? ["bank.finbank.a", "bank.finbank.b", "bank.finbank.oauth", "bank.banco"]
    : clientDefaultPlatformSlugs;

  const platforms =
    suspend(async () => {
      try {
        const resp = await getPlatforms(
          searchText === "" ? null : searchText,
          searchText === "" ? defaultPlatformSlugs : null,
        );
        return resp.platforms;
      } catch (e) {
        if (e instanceof RequestError) {
          setRequestError(e);
        } else {
          asyncThrow(e);
        }
      }
    }, [searchText]) ?? [];

  return (
    <div className="flex flex-col h-full w-full mt-6 md:mt-10 gap-y-2">
      {searchText === "" && <div className="text-gray-700 font-light text-xs">Most popular banks</div>}
      {searchText !== "" && platforms.length === 0 && (
        <div className="w-full flex flex-col items-center pt-6">
          <div className="text-gray-700 font-semibold text-sm">No banks found matching {searchText}.</div>
          <div className="text-gray-700 font-light text-sm">Try refining your search.</div>
        </div>
      )}
      {platforms.map((platform) => (
        <div
          key={platform.platformId}
          onClick={() => onSelect(platform)}
          className="flex items-center justify-start w-full p-2 md:p-4 border-1 border-navy-100 hover:border-green-500 cursor-pointer">
          <div className="shrink-0 mr-2 md:mr-4">
            <PlatformIcon icon={platform.icon} />
          </div>
          <div className="grow min-w-0 overflow-x-hidden">
            <div className="truncate font-medium">{platform.displayName}</div>
            <div className="truncate  text-sm text-gray-500">{platform.homepageUrl}</div>
          </div>
        </div>
      ))}
      {searchText !== "" && platforms.length > 0 && (
        <div className="w-full flex flex-col items-center pt-6">
          <div className="text-gray-700 font-semibold text-sm">{`Don't see your bank?`}</div>
          <div className="text-gray-700 font-light text-sm">Try refining your search.</div>
        </div>
      )}
    </div>
  );
}

export interface PlatformSearchSelectorProps {
  onSelect: (p: Platform) => void;
  query?: string;
}

export default function PlatformSearchSelector({onSelect, query = ""}: PlatformSearchSelectorProps) {
  const [debouncedSearchText, _setDebouncedSearchText] = useState<string>(query);
  const updateURLWithoutRerender = (text: string) => {
    const url = new URL(window.location.href);
    text ? url.searchParams.set("search", text) : url.searchParams.delete("search");
    window.history.replaceState({}, "", url.toString());
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchText = useCallback(
    debounce((text: string) => {
      _setDebouncedSearchText(text);
      updateURLWithoutRerender(text);
    }, 200),
    [],
  );

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    debounceSearchText(e.target.value);
  }

  return (
    <div className="flex flex-col w-full h-full items-center">
      <FormTextField
        fullWidth
        id="search-input"
        name="search-input"
        label="Search"
        defaultValue={query}
        onChange={handleChange}
      />
      <Suspense fallback={<PlatformSearchListFallback />}>
        <div className="flex flex-col w-full h-full">
          <PlatformSearchList searchText={debouncedSearchText} onSelect={onSelect} />
        </div>
      </Suspense>
    </div>
  );
}
