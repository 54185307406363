import React, {ReactElement, ReactNode} from "react";

import useDetectInvalidPageAccess from "../../hooks/useDetectInvalidPageAccess";
import PageTemplate from "./PageTemplate";

export default function AuthenticatedPageTemplate({children}: {children: ReactNode}): ReactElement {
  useDetectInvalidPageAccess();
  return <PageTemplate>{children}</PageTemplate>;
}

export function AuthenticatedPage({children}: {children: ReactNode}): ReactElement {
  useDetectInvalidPageAccess();
  return <div className="flex h-full w-full">{children}</div>;
}
