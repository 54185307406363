import {ClockIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";

import {Account, SyncResult} from "../../interfaces";

interface FetchAccountsSummaryProps {
  syncResult: SyncResult;
}

export default function FetchAccountsSummary({syncResult}: FetchAccountsSummaryProps): ReactElement {
  const {accounts, subuserAuthorization} = syncResult;

  const subuserCreatedNoAccountData = subuserAuthorization && accounts.length === 0;
  const getAccountRepr = (idx: number, acct: Account): string =>
    [acct.displayName, acct.accountMask && `****${acct.accountMask}`].filter((e) => e).join(" ") || `Account ${idx}`;

  return accounts.length ? (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <img src="/assets/icons/green_check.svg" className="w-4 md:w-5 h-4 md:h-5" alt="Green check" />
        <span className="ml-2 md:ml-4 text-navy-500 font-semibold text-sm md:text-lg">Account data retrieved</span>
      </div>
      <div className="text-navy-300 ml-6 md:ml-9">
        {accounts.map((a, i) => (
          <div key={i}>{getAccountRepr(i, a)}</div>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        {subuserCreatedNoAccountData ? (
          <ClockIcon className="w-5 h-5" />
        ) : (
          <img src="/assets/icons/red_exclamation.svg" className="w-5 h-5" alt="Red exclamation" />
        )}
        <div className="ml-2 md:ml-4">
          <div>
            <span className="text-navy-500 font-semibold text-sm md:text-lg">
              {subuserCreatedNoAccountData ? "Account data pending retrieval" : "Account data not retrieved"}
            </span>
          </div>
          <div>
            <span className="text-navy-300">
              {`Your account data was not retrieved${
                subuserCreatedNoAccountData
                  ? ", but we will retry later. No further action is necessary."
                  : ". Please add another account or try again later."
              }`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
