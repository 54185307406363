import React, {ReactElement} from "react";

import {MarkdownType} from "../interfaces";
import Markdown from "./Markdown";

interface ServiceInfoProps {
  prominent?: boolean; // whether displayed on landing page or secondary component
  content: MarkdownType;
}

export default function ServiceInfo({prominent = true, content}: ServiceInfoProps): ReactElement {
  return (
    <div className="text-md md:text-lg">
      {/* TODO: Make this dogshit clearer*/}
      <span className={`${prominent ? "md:mb-10" : "mb-4"} font-semibold text-navy-500 text-sm md:text-lg`}>
        How it works
      </span>
      {!prominent && <div className="border-b-4 border-green-500 mb-1"></div>}
      <Markdown markdown={content} />
    </div>
  );
}
