import {uuid4} from "@sentry/utils";
import React, {ReactElement, useEffect, useRef, useState} from "react";

import {useWebSocketQueue} from "../../contexts/WebSocketQueueContext";
import {PromptEventPayload, ResponseEventPayload, SyncEventType} from "../../models/event";
import {SyncEventMessage} from "../../models/message";
import {AnyPromptResponse, Prompt, PromptResponseType, PromptType, ResourceResponse} from "../../models/prompt";
import ContentBody from "../../pages/template/ContentBody";
import {ContentFooter} from "../../pages/template/ContentFooter";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getCurrentSyncSession, setError} from "../../store/syncSessionSlice";
import {UUID} from "../../types";
import {Button} from "../Buttons/Button";
import {DeadlineIndicator} from "../DeadlineIndicator";
import {FileUpload} from "../Form/FileUpload/FileUpload";
import {FormTextField} from "../FormTextField";
import Markdown from "../Markdown";
import {PasscodeInput} from "../PasscodeInput";
import {SingleOption} from "../SingleOption";
import PromptErrorMessage from "./PromptErrorMessage";

function parsePromptResponses(ev: React.FormEvent<HTMLFormElement>): Array<AnyPromptResponse> {
  const responses: Array<AnyPromptResponse> = [];
  const formData = new FormData(ev.currentTarget);
  Array.from(formData.keys()).forEach((promptId) => {
    const input = ev.currentTarget.querySelector(`input[name="${promptId}"]`) as HTMLInputElement;
    const responseType = input.getAttribute("data-response-type") as PromptResponseType;
    const value = formData.get(promptId);
    if (responseType === PromptResponseType.Resource) {
      const v = JSON.parse(value as string) as ResourceResponse[];
      const resp = {promptId, responseType, value: v} as AnyPromptResponse;
      responses.push(resp);
    } else {
      responses.push({promptId, responseType, value} as AnyPromptResponse);
    }
  });
  return responses;
}

export function PromptMessage({title, prompts, deadline}: PromptEventPayload): ReactElement {
  const {setIsWaiting, signalReadyToAdvanceQueue, sendMessage} = useWebSocketQueue();
  const {error: prevError, sessionId} = useAppSelector(getCurrentSyncSession);
  const dispatch = useAppDispatch();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const buttonPromptRefs = useRef<Record<string, React.RefObject<HTMLInputElement>>>({});
  let timerDuration = null;

  // build prompt ref for button prompt
  useEffect(() => {
    const buttonPrompt = prompts.find((p) => p.promptType === PromptType.Button);
    if (buttonPrompt) {
      if (prompts.length > 1)
        // TODO 2023-10-04: this is a hack, button prompt types need to be refactored
        throw new Error("Button prompt must be the only prompt");
      const refs: Record<string, React.RefObject<HTMLInputElement>> = {};
      buttonPrompt.options?.forEach((opt) => {
        refs[opt.value] = React.createRef<HTMLInputElement>();
      });
      buttonPromptRefs.current = refs;
    }
  }, [prompts]);

  function onPromptInput(ev: React.FormEvent<HTMLFormElement>): void {
    setIsFormValid(ev.currentTarget.checkValidity());
  }

  function onPromptSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();
    setSubmitted(true);
    setIsFormLoading(true);
    const responses = parsePromptResponses(ev);
    const payload: ResponseEventPayload = {eventId: uuid4() as UUID, eventType: SyncEventType.Response, responses};
    const message = new SyncEventMessage({messageId: uuid4() as UUID, sessionId: sessionId as UUID, payload});
    sendMessage(message);
    signalReadyToAdvanceQueue();
    setIsFormLoading(false);
    setIsWaiting(true);
  }

  function onButtonPromptClick(value: string): void {
    const buttonPromptInput = buttonPromptRefs.current[value]?.current;
    if (buttonPromptInput) {
      buttonPromptInput.checked = true;
    }

    if (formRef.current) {
      formRef?.current?.requestSubmit();
    }
  }

  function parsePromptLabelMarkdown(
    content: string,
    assets: Readonly<{
      [key: string]: string;
    }> | null,
  ): ReactElement {
    function extractContentInsideBrackets(input: string) {
      const regex = /\[([^\]]+)\]/;
      const match = input.match(regex);
      return match && match[1] ? match[1] : null;
    }

    // TODO 2024-07-29: we should move away from this non-standard pattern of using \t to
    // separate assets and text content. Instead, assets should be a totally separate field
    // on the prompt, and should be displayed in the order they are specified.
    const [iconName, labelText] = content.split("\t");

    // contained no markdown "header"
    if (iconName === content) return <Markdown markdown={content} />;

    return (
      <div className="flex flex-col items-center">
        <div>
          <img
            alt="guidance"
            className="max-h-[450px]"
            src={assets?.[`${extractContentInsideBrackets(iconName) ?? ""}`]}></img>
        </div>
        <Markdown markdown={labelText} />
      </div>
    );
  }

  function buildPromptComponent(prompt: Prompt): ReactElement {
    const [minLength, maxLength] = prompt.length;
    const pattern = minLength !== null && maxLength !== null ? `^.{${minLength},${maxLength}}$` : undefined;
    const errorText =
      minLength !== null && maxLength !== null ? `Value must be between ${minLength} and ${maxLength} characters` : "";

    const labelStyles = "mt-4 mb-8 text-md md:text-lg font-light";

    switch (prompt.promptType) {
      case PromptType.Button:
        const isCronto = prompt?.assets?.["svb-cronto-code"] ? true : false;
        if (prompt.options === null) throw new Error("Button prompt must have options");
        // TODO 2023-12-18: generalize cronto retrieval and remove svb hardcoding here
        return (
          <div>
            {isCronto && (
              <div className="flex items-center justify-center mt-8">
                <img alt="crontocode" src={prompt?.assets?.["svb-cronto-code"] ?? ""}></img>
                {(() => {
                  // hack: IIFE to set crontocode specific countdown timer duration
                  timerDuration = prompt?.assets?.["svb-cronto-timer-duration"]
                    ? parseInt(prompt?.assets?.["svb-cronto-timer-duration"])
                    : 60;
                  return null;
                })()}
              </div>
            )}
            <div className={labelStyles}>{parsePromptLabelMarkdown(prompt.label, prompt?.assets ?? null)}</div>
            {prompt.options.map((opt, i) => (
              <div key={i} className="mb-4">
                <input
                  name={prompt.promptId}
                  id={opt.label}
                  type="radio"
                  value={opt.value}
                  ref={buttonPromptRefs.current[opt.value]}
                  className="hidden"
                  disabled={expired}
                  data-response-type={PromptResponseType.Scalar}
                />
                <Button
                  type="button"
                  name={prompt.promptId}
                  fullWidth
                  label={opt.label}
                  value={opt.value}
                  primary={i === 0 ? true : false}
                  onClick={() => void onButtonPromptClick(opt.value)}
                  loading={isFormLoading || submitted}
                  disabled={expired || isFormLoading || submitted}
                />
              </div>
            ))}
          </div>
        );
      case PromptType.Text:
        return (
          <>
            <Markdown markdown={prompt.label} />
            <div className="flex flex-col justify-center">
              <FormTextField
                name={prompt.promptId}
                label="Enter value"
                pattern={pattern}
                errorText={errorText}
                required
                disabled={expired}
                fullWidth
                data-response-type={PromptResponseType.Scalar}
              />
            </div>
          </>
        );
      case PromptType.Numeric:
        if (minLength === maxLength && maxLength !== null)
          return (
            <>
              <Markdown markdown={prompt.label} />
              <div className="flex flex-col justify-center items-center">
                <div className="my-6">
                  <PasscodeInput
                    inputLength={maxLength}
                    inputName={prompt.promptId}
                    regex={"[0-9a-zA-Z]"}
                    disabled={expired}
                    data-response-type={PromptResponseType.Scalar}
                  />
                </div>
              </div>
            </>
          );
        else throw new Error("Numeric prompt must have equal min and max length");
      case PromptType.Password:
        return (
          <>
            <Markdown markdown={prompt.label} />
            <div className="flex flex-col justify-center">
              <FormTextField
                name={prompt.promptId}
                label="Password"
                pattern={pattern}
                errorText={errorText}
                type="password"
                required
                disabled={expired}
                fullWidth
                data-response-type={PromptResponseType.Scalar}
              />
            </div>
          </>
        );
      case PromptType.File:
        throw new Error("File prompt not implemented");
      case PromptType.Files:
        return (
          <>
            <Markdown markdown={prompt.label} />
            <div className="mt-10">
              <FileUpload sessionId={sessionId} promptId={prompt.promptId} />
            </div>
          </>
        );
      case PromptType.SingleOption:
        if (prompt.options === null) throw new Error("SingleOption prompt must have options");
        return (
          <>
            <Markdown markdown={prompt.label} />
            <div className="my-6">
              <SingleOption
                promptId={prompt.promptId}
                options={prompt.options}
                disabled={expired}
                data-response-type={PromptResponseType.Scalar}
              />
            </div>
          </>
        );
      case PromptType.MultiOption:
        throw new Error("MultiOption prompt not implemented");
      default:
        throw new Error("Invalid prompt type");
    }
  }

  const promptComponents = prompts.map((p) => buildPromptComponent(p));

  let button;
  if (prompts[0].promptType === PromptType.Button) button = null;
  else
    button = (
      <Button
        type="submit"
        label="Submit"
        primary
        fullWidth
        disabled={!isFormValid || isFormLoading || expired || submitted}
        loading={isFormLoading || submitted}
        onClick={() => {
          // prevent previous prompt error from showing on the next prompt
          dispatch(setError({sessionId, error: null}));
          formRef?.current?.requestSubmit();
        }}
      />
    );

  const deadlineIndicator = deadline ? (
    <div>
      <DeadlineIndicator
        timerDuration={timerDuration}
        showExpiredState={false}
        deadline={deadline}
        onExpiration={() => {
          setExpired(true);
          signalReadyToAdvanceQueue();
        }}
      />
      <div style={{marginBottom: "16px"}} />
    </div>
  ) : null;

  const titleMarkdown = title ? (title.trim().startsWith("#") ? title : `# ${title}`) : "# Prompt";

  return (
    <div className="flex flex-col h-full w-full items-center">
      <ContentBody>
        <div>
          <Markdown markdown={titleMarkdown} />
        </div>
        <form
          ref={formRef}
          className="mt-4 md:mt-10"
          onInput={(ev) => void onPromptInput(ev)}
          onSubmit={(ev) => void onPromptSubmit(ev)}>
          {promptComponents.map((element, i) => (
            <div key={i}>{element}</div>
          ))}
          {prevError && (
            <div role="alert" className="-mt-2 mb-2">
              <PromptErrorMessage error={prevError} />
            </div>
          )}
          <button type="submit" className="hidden" data-testid="prompt-submit" />
        </form>
      </ContentBody>
      <ContentFooter>
        <div className="max-md:pb-2 w-full">
          {deadlineIndicator}
          {button}
        </div>
      </ContentFooter>
    </div>
  );
}
