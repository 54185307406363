import React, {ReactElement} from "react";

import {useCopy} from "../../hooks/useCopy";
import AboutUs from "../AboutUs";
import CustomerLogo from "../CustomerLogo";
import ServiceInfo from "../ServiceInfo";
import ValueProposition from "../ValueProposition";

interface DesktopSidebarProps {
  customerLogo: string | null;
}

export default function DesktopSidebar({customerLogo}: DesktopSidebarProps): ReactElement {
  const {landSubtitle, landServiceInfo} = useCopy();

  return (
    <div className="grow h-full min-w-xs max-w-sm bg-green-50 px-6 py-4 border-navy-100 border-r-1 mr-2">
      <div className="flex flex-col h-full">
        <div className="flex w-[50%]">
          <CustomerLogo customerLogo={customerLogo} />
        </div>
        <div className="md:mt-10">
          <ValueProposition landSubtitle={landSubtitle} landBody="" />
        </div>
        <div className="md:pr-2 [&_*]:!text-base mb-6">
          <ServiceInfo content={landServiceInfo} prominent={false} />
        </div>
        <AboutUs />
      </div>
    </div>
  );
}
