import React, {ReactElement, ReactNode, useState} from "react";
import {useLocation} from "react-router-dom";

import BrandTag from "../../components/BrandTag";
import AnimatedMobileSlideUp from "../../components/Modal/AnimatedMobileSlideUp";
import {ENTRY_PATH} from "../../constants/constants";

export interface ContentFooterProps {
  children: ReactNode;
}

export function ContentFooter({children}: ContentFooterProps): ReactElement {
  const location = useLocation();
  const [isSlideUpVisible, setIsSlideUpVisible] = useState<boolean>(false);

  const brandTag = <BrandTag showInfoIcon={location.pathname !== ENTRY_PATH} />;

  return (
    <div className="flex-none w-full h-auto max-md:mt-4 md:pt-6">
      <div className="flex flex-col gap-y-4 md:gap-y-6">
        {children}
        <button
          className="flex flex-row md:hidden w-full items-center justify-center"
          onClick={() => setIsSlideUpVisible(true)}>
          {brandTag}
        </button>
        <div className="flex flex-row w-full max-md:hidden items-center justify-center">{brandTag}</div>
        <AnimatedMobileSlideUp isOpen={isSlideUpVisible} onClose={() => setIsSlideUpVisible(false)} />
      </div>
    </div>
  );
}
