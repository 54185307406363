import {motion} from "framer-motion";
import React, {ReactElement} from "react";
import {useLocation} from "react-router-dom";

import {ENTRY_PATH, transitionVariants} from "../../constants/constants";
import {getClientConfig} from "../../store/clientConfigSlice";
import {useAppSelector} from "../../store/hooks";
import DesktopMinimalSidebar from "./DesktopMinimalSidebar";
import DesktopSidebar from "./DesktopSidebar";

export default function AnimatedDesktopSidebar(): ReactElement {
  const location = useLocation();
  const isVisible = location.pathname !== ENTRY_PATH;
  const {customerLogo} = useAppSelector(getClientConfig);

  return (
    <motion.div
      className="flex"
      key={isVisible ? "true" : "false"}
      initial={isVisible ? "hidden" : "visible"}
      animate="visible"
      exit="exit"
      variants={{
        hidden: {opacity: 0, x: isVisible ? -100 : 0},
        visible: transitionVariants.visible,
        exit: transitionVariants.exit,
      }}>
      {isVisible ? (
        <DesktopSidebar customerLogo={customerLogo} />
      ) : (
        <DesktopMinimalSidebar customerLogo={customerLogo} />
      )}
    </motion.div>
  );
}
