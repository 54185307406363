import React, {ReactElement, useState} from "react";

import {Button} from "../components/Buttons/Button";
import PlusButton from "../components/Buttons/PlusButton";
import ContentHeader from "../components/ContentHeader";
import {SyncResultSummaryGroup} from "../components/Results/SyncResultSummary";
import {useCopy} from "../hooks/useCopy";
import useStateNavigation from "../hooks/useStateNavigate";
import {getOAuthParams} from "../store/authSlice";
import {useAppSelector} from "../store/hooks";
import {getSyncSessions} from "../store/syncSessionSlice";
import {handleBackToClient} from "../utils";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import {ContentFooter} from "./template/ContentFooter";

export default function Summary(): ReactElement {
  const {navigateNext} = useStateNavigation();
  const auth = useAppSelector(getOAuthParams);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const {summaryTitle, summarySubtitle, summaryCTA} = useCopy();

  // TODO: 2024-08-20 - Ensure <SyncResultSummaryBox/> list is sorted by sync start time, not an issue for chrome browser rn
  const syncTargetIds = Object.values(useAppSelector(getSyncSessions)).map((syncSession) => syncSession.syncTargetId);

  async function onSubmit(): Promise<void> {
    setIsFormLoading(true);
    await handleBackToClient(auth.redirectUri, auth.state);
    setIsFormLoading(false);
  }

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title={summaryTitle} />
        <div className="flex mt-6 md:mt-10 w-full">
          <SyncResultSummaryGroup syncTargetIds={syncTargetIds} />
        </div>
        <div className="mt-4 md:mt-8">
          <PlusButton
            onClick={() => {
              navigateNext("/platforms");
            }}>
            <span>Add another account</span>
          </PlusButton>
        </div>
        <div className="mt-4 md:mt-8">
          <span className="font-semibold text-navy-500 text-lg md:text-xl">{summarySubtitle}</span>
        </div>
      </ContentBody>
      <ContentFooter>
        <Button
          primary
          fullWidth
          label={summaryCTA}
          loading={isFormLoading}
          disabled={isFormLoading}
          onClick={() => void onSubmit()}
        />
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
