import {XMarkIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon, DocumentIcon, ExclamationCircleIcon, TrashIcon} from "@heroicons/react/24/solid";
import React, {ReactElement, useState} from "react";

import {FileTransferItem} from "../../../interfaces";
import {LoadingSpinner} from "../../Suspense/Loading";

interface FileRowProps {
  item: FileTransferItem;
  handleRemoveFile: (resourceId: string) => Promise<void>;
  handleCancelUpload: (fileId: string) => void;
}

export function FileRow({item, handleRemoveFile, handleCancelUpload}: FileRowProps): ReactElement {
  const [actionIsLoading, setActionIsLoading] = useState<boolean>(false);
  const progressPercentage =
    item.progress && item.progress.total > 0 ? (item.progress.current / item.progress.total) * 100 : 0;

  async function removeFile(item: FileTransferItem): Promise<void> {
    if (!item.file?.resourceId) return;
    setActionIsLoading(true);
    await handleRemoveFile(item.file?.resourceId);
    setActionIsLoading(false);
  }

  function cancelUpload(item: FileTransferItem): void {
    if (!item.file?.fileId) return;
    setActionIsLoading(true);
    handleCancelUpload(item.file?.fileId);
    setActionIsLoading(false);
  }

  return (
    <div className="relative border" data-testid="file-row">
      <div className="flex items-center justify-between p-5 w-full">
        {item.isUploading ? (
          <DocumentIcon className="h-5 w-5 text-gray-400 flex-shrink-0" />
        ) : item.error ? (
          <ExclamationCircleIcon className="h-5 w-5 text-red-500 flex-shrink-0" />
        ) : (
          <CheckCircleIcon className="h-5 w-5 text-green-500 flex-shrink-0" />
        )}
        <span className="mx-4 truncate text-gray-600 overflow-hidden whitespace-nowrap flex-grow">
          {item.file?.name}
        </span>
        <span className="ml-auto truncate text-red-500 text-xs flex-shrink-0">{item.error}</span>
        {item.isUploading ? (
          <span
            data-testid="cancel-upload"
            className="ml-2 text-gray-400 text-xs cursor-pointer flex-shrink-0"
            onClick={() => void cancelUpload(item)}>
            Cancel
          </span>
        ) : item.error ? (
          <XMarkIcon
            data-testid="cancel-upload-error"
            className="ml-2 h-5 w-5 text-red-500 cursor-pointer flex-shrink-0"
            onClick={() => void cancelUpload(item)}
          />
        ) : actionIsLoading ? (
          <LoadingSpinner size={5} />
        ) : (
          <TrashIcon
            data-testid="remove-file"
            className="ml-2 h-5 w-5 text-gray-400 cursor-pointer flex-shrink-0"
            onClick={() => void removeFile(item)}
          />
        )}
      </div>
      <div className="-mt-1 h-1 w-full bg-gray-200 absolute">
        <div
          className={`h-full ${item.error ? "bg-red-500" : "bg-green-500"}`}
          style={{width: `${progressPercentage}%`, transition: "width 0.2s ease-in-out"}}></div>
      </div>
    </div>
  );
}
