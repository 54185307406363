import React, {ReactElement} from "react";

import {SyncResult} from "../../interfaces";
import {SubuserStrategy} from "../OngoingConnectivity/OngoingConnectivityCommon";
import MobileShowMoreExpander from "./MobileShowMoreExpander";

interface OngoingConnectivitySummaryProps {
  syncResult: SyncResult;
}

const limitedServiceContinued =
  "Please return at a later time to enable data refresh. You may need an account administrator to enable this.";

export default function OngoingConnectivitySummary({syncResult}: OngoingConnectivitySummaryProps): ReactElement {
  const {
    platform: {name: platformName, subuserStrategy},
    subuserAuthorization,
  } = syncResult;

  if (subuserStrategy === SubuserStrategy.Unsupported) {
    return <div className="-mb-2 md:-mb-4"></div>;
  }

  return subuserAuthorization ? (
    <div>
      <div className="flex flex-row items-center">
        <img src="/assets/icons/green_check.svg" className="w-4 md:w-5 h-4 md:h-5" alt="Green check" />
        <span className="ml-2 md:ml-4 text-navy-500 font-semibold text-sm md:text-lg">Data refresh enabled</span>
      </div>
      <div className="md:ml-3 text-navy-300">
        <div className="ml-6">Your account is configured to stay up to date and share new transactions.</div>
      </div>
    </div>
  ) : (
    <div>
      <div className="flex flex-row items-center">
        <img src="/assets/icons/red_exclamation.svg" className="w-4 md:w-5 h-4 md:h-5" alt="Red exclamation" />
        <span className="ml-2 md:ml-4 text-navy-500 font-semibold text-sm md:text-lg">Data will not refresh</span>
      </div>
      <div className="md:ml-3 text-navy-300">
        <div className="ml-6">
          {`Your service at ${platformName} may be limited. `}
          <span className="md:hidden">
            <MobileShowMoreExpander text={limitedServiceContinued} />
          </span>
          <span className="max-md:hidden">{limitedServiceContinued}</span>
        </div>
      </div>
    </div>
  );
}
