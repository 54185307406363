import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";

import useStateNavigate from "../../hooks/useStateNavigate";
import ContentBody from "../../pages/template/ContentBody";
import {ContentFooter} from "../../pages/template/ContentFooter";
import {getClientConfig} from "../../store/clientConfigSlice";
import {useAppSelector} from "../../store/hooks";
import {getCurrentSyncSession} from "../../store/syncSessionSlice";
import {Button} from "../Buttons/Button";
import ContentHeader from "../ContentHeader";
import {PaddedPlatformLogo} from "../PlatformLogo";

export default function SyncErrorScreen(): ReactElement {
  const {navigateBack} = useStateNavigate();
  const {
    platformConfig: {logo, displayName},
  } = useAppSelector(getCurrentSyncSession);
  const clientConfig = useAppSelector(getClientConfig);

  return (
    <div className="flex flex-col items-center h-full w-full">
      <ContentBody>
        <div className="flex flex-row items-center">
          <ExclamationCircleIcon className="h-10 w-10 mr-4 fill-red-500 stroke-white" />
          <ContentHeader title="Something went wrong." />
        </div>
        <div className="mt-4 md:mt-10">
          <p>We were unable to connect your account. You can retry below, or you can connect to another bank.</p>
        </div>
        <div className="mt-4 md:mt-10">
          <p>You can also return to {clientConfig.customerShortName} and try again later.</p>
        </div>
        <PaddedPlatformLogo logo={logo} displayName={displayName} />
      </ContentBody>
      <ContentFooter>
        <Button type="submit" label="Retry" primary fullWidth onClick={() => navigateBack()} />
      </ContentFooter>
    </div>
  );
}
