import React, {ReactElement} from "react";

type ValuePropositionProps = Readonly<{
  prominent?: boolean; // whether displayed on landing page or secondary component
  landBody: string;
  landSubtitle: string;
}>;

export default function ValueProposition({
  prominent = true,
  landBody,
  landSubtitle,
}: ValuePropositionProps): ReactElement {
  return (
    <div className="text-navy-500">
      <p className="text-md md:text-xl font-medium">{landSubtitle}</p>
      <p className={`max-md:hidden text-md md:text-lg mt-4 md:mt-10 ${prominent ? "" : "text-xs"}`}>{landBody}</p>
    </div>
  );
}
