import {PlusIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";

interface PlusButtonProps {
  children?: ReactElement;
  onClick: () => void;
}

export default function PlusButton({children, onClick}: PlusButtonProps): ReactElement {
  return (
    <button className="flex flex-row justify-start items-center gap-x-2" onClick={onClick}>
      <div className="flex flex-col items-center justify-center border-navy-100 border-1">
        <PlusIcon className="h-5 w-5 m-2 stroke-navy-500" />
      </div>
      {children && children}
    </button>
  );
}
