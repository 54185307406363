import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {AuthState, AuthenticationStatus, InitToken, OAuthParams} from "../interfaces";
import {parseInitToken} from "../utils";
import {RootState} from ".";

const initialState: AuthState = {
  oauthParams: {} as OAuthParams,
  initTokenParams: {} as InitToken,
  hasInitialized: false,
  authenticationStatus: {emailAuthenticated: false, emailChallenged: null} as AuthenticationStatus,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOAuthParams: (state, action: PayloadAction<OAuthParams>) => {
      const initTokenParams = parseInitToken(action.payload.initToken);
      state.oauthParams = action.payload;
      state.initTokenParams = initTokenParams;
      state.authenticationStatus = {emailAuthenticated: false, emailChallenged: null} as AuthenticationStatus;
    },
    setAuthenticationParams: (state, action: PayloadAction<AuthenticationStatus>) => {
      state.authenticationStatus = action.payload;
    },
    setHasInitialized: (state, action: PayloadAction<boolean>) => {
      state.hasInitialized = action.payload;
    },
  },
});

export const {setOAuthParams, setAuthenticationParams, setHasInitialized} = authSlice.actions;

export const getOAuthParams = (state: RootState) => state.auth.oauthParams;

export const getInitTokenParams = (state: RootState) => state.auth.initTokenParams;

export const getAuthenticationParams = (state: RootState) => state.auth.authenticationStatus;

export const getHasInitialized = (state: RootState) => state.auth.hasInitialized;

export default authSlice.reducer;
