import {XMarkIcon} from "@heroicons/react/24/outline";
import {motion} from "framer-motion";
import {ReactElement} from "react";
import React from "react";
import ReactDOM from "react-dom";

import {useCopy} from "../../hooks/useCopy";
import ContentBody from "../../pages/template/ContentBody";
import {getClientConfig} from "../../store/clientConfigSlice";
import {useAppSelector} from "../../store/hooks";
import AboutUs from "../AboutUs";
import CustomerLogo from "../CustomerLogo";
import ServiceInfo from "../ServiceInfo";
import ValueProposition from "../ValueProposition";

interface SlideUpProps {
  isOpen: boolean;
  onClose: () => void;
}

const slideUpVariants = {
  hidden: {y: "100%", opacity: 0},
  visible: {y: "0%", opacity: 1, transition: {duration: 0.25}},
  exit: {y: "100%", opacity: 0, transition: {duration: 0.25}},
};

function SlideUpContent(): ReactElement {
  const {customerLogo} = useAppSelector(getClientConfig);
  const {landServiceInfo, slideupSubtitle} = useCopy();

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex max-w-[30%]">
        <CustomerLogo customerLogo={customerLogo} />
      </div>
      <ContentBody>
        <div className="mt-4">
          <ValueProposition landSubtitle={slideupSubtitle} landBody={""} />
        </div>
        <div className="mt-4 mb-8">
          <ServiceInfo content={landServiceInfo} />
        </div>
      </ContentBody>
      <div className="flex-none w-full h-auto">
        <div className="flex flex-row justify-between border-t-2 border-gray-300 pt-6">
          <a href="https://usebasis.co" target="_blank" rel="noopener noreferrer">
            <img src="/assets/basis/basis_logo.svg" alt="" className="h-6 w-auto" />
          </a>
          <AboutUs />
        </div>
      </div>
    </div>
  );
}

export default function AnimatedMobileSlideUpContainer({isOpen, onClose}: SlideUpProps): ReactElement | null {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <motion.div
        className="absolute bottom-0 bg-green-50 w-full h-[80%] z-10 pt-6 px-6"
        variants={slideUpVariants}
        initial="hidden"
        animate="visible"
        exit="exit">
        <button onClick={onClose} className="absolute top-6 right-6 text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-6 w-6 stroke-navy-500" />
        </button>
        <SlideUpContent />
      </motion.div>
    </div>,
    document.body,
  );
}
