import {ArrowLeftIcon, XMarkIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";
import {useLocation} from "react-router-dom";

import {useMobileHeaderBar} from "../../contexts/MobileHeaderBarContext";
import {getOAuthParams} from "../../store/authSlice";
import {getClientConfig} from "../../store/clientConfigSlice";
import {useAppSelector} from "../../store/hooks";
import {convertBase64SVGToImageUrl, handleBackToClient} from "../../utils";
import GoBackButton from "../Buttons/GoBackButton";

export function HeaderBarMobile(): ReactElement {
  const {customerLogo} = useAppSelector(getClientConfig);
  const auth = useAppSelector(getOAuthParams);
  const location = useLocation();
  const {
    goBackControl: {showGoBack, goBackCallback},
  } = useMobileHeaderBar();

  return (
    <div className="flex justify-between items-center px-4 py-3 bg-green-100 text-sm">
      <div className={`${showGoBack ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
        <GoBackButton onClick={() => goBackCallback()} />
      </div>
      <div className="flex justify-end">
        <img src={convertBase64SVGToImageUrl(customerLogo)} alt="customer logo" className="h-6 w-auto" />
      </div>
      <div className={`${location.pathname !== "/summary" ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
        <button
          className="flex flex-row items-center"
          onClick={() => void handleBackToClient(auth.redirectUri, auth.state)}>
          <XMarkIcon className="h-6 w-6 stroke-navy-500" />
        </button>
      </div>
    </div>
  );
}

export function HeaderBarDesktop(): ReactElement {
  const {customerShortName} = useAppSelector(getClientConfig);
  const auth = useAppSelector(getOAuthParams);

  return (
    <div className="flex justify-between items-center px-4 py-2 border-b-1 border-navy-100 text-sm">
      <button
        className="flex flex-row items-center"
        onClick={() => void handleBackToClient(auth.redirectUri, auth.state)}>
        <div className="flex items-center">
          <ArrowLeftIcon className="h-5 w-5 stroke-navy-500" />
          <span className="pl-2 ">Return to {customerShortName}</span>
        </div>
      </button>
      <div className="flex justify-end">
        <img src="/assets/basis/basis_logo.svg" alt="Basis" className="h-6 w-auto" />
      </div>
    </div>
  );
}

export default function HeaderBar(): ReactElement {
  return (
    <div className="h-full w-full">
      <div className="md:hidden">
        <HeaderBarMobile />
      </div>
      <div className="max-md:hidden">
        <HeaderBarDesktop />
      </div>
    </div>
  );
}
