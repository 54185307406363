export const transitionEase = {
  duration: 0.25,
  ease: "easeInOut",
};

export const transitionVariants = {
  hidden: {opacity: 0},
  visible: {opacity: 1, x: 0, y: 0, transition: transitionEase},
  exit: {opacity: 0, transition: transitionEase},
};

export const ENTRY_PATH = "/oauth";

export const defaultErrorMessage = "An unexpected error occurred. Please try again.";
