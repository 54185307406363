import React from "react";

function DashedLine(): React.ReactElement {
  return <div className="border-dashed border-2 border-green-500 w-12 mx-2"></div>;
}

export default function ServicesHintDiagram(): React.ReactElement {
  return (
    <div className="flex flex-row justify-center items-center">
      <img src="/assets/icons/institution.svg" alt="institution logo"></img>
      <DashedLine />
      <img src="/assets/icons/basis.svg" alt="basis logo"></img>
      <DashedLine />
      <img src="/assets/icons/company.svg" alt="customer logo"></img>
    </div>
  );
}
