import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import GoBackButton from "../components/Buttons/GoBackButton";
import ContentHeader from "../components/ContentHeader";
import PlatformSearchSelector from "../components/PlatformSearchSelector";
import {useMobileHeaderBar} from "../contexts/MobileHeaderBarContext";
import useStateNavigate from "../hooks/useStateNavigate";
import {Platform} from "../interfaces";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import {ContentFooter} from "./template/ContentFooter";

export default function InstitutionSearch() {
  const {navigateNext, navigateBack} = useStateNavigate();
  const {setGoBackControl} = useMobileHeaderBar();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchText = searchParams.get("search") ?? "";

  function handleSelect(item: Platform): void {
    navigateNext(`/platforms/${item.platformId}`);
  }

  useEffect(() => {
    setGoBackControl({showGoBack: true, goBackCallback: navigateBack});
  }, [navigateBack, setGoBackControl]);

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title="Select your bank" />
        <div className="mt-4 md:mt-10">
          <PlatformSearchSelector onSelect={handleSelect} query={searchText} />
        </div>
      </ContentBody>
      <ContentFooter>
        <div className="flex mt-4 w-full max-md:hidden">
          <GoBackButton onClick={() => navigateBack()} />
        </div>
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
