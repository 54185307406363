import React, {ReactElement} from "react";

import AboutUs from "../AboutUs";
import CustomerLogo from "../CustomerLogo";

interface DesktopMinimalSidebarProps {
  customerLogo?: string | null;
  shouldDisplayLogo?: boolean;
}

export default function DesktopMinimalSidebar({
  customerLogo = null, // fallback to placeholder if none provided
  shouldDisplayLogo = true, // explicitly avoid fallback
}: DesktopMinimalSidebarProps): ReactElement {
  return (
    <div className="absolute top-0 left-0 flex flex-col h-full w-[300px] justify-between px-6 py-4">
      {shouldDisplayLogo && (
        <div className="flex w-[50%]">
          <CustomerLogo customerLogo={customerLogo} />
        </div>
      )}
      <AboutUs />
    </div>
  );
}
