import {motion} from "framer-motion";
import React, {ReactElement, useEffect, useState} from "react";

import useMotionProps from "../../hooks/useMotionProps";
import {LoadingSpinner, LoadingSpinnerProps} from "./Loading";

interface SyncLoadingSpinnerProps extends LoadingSpinnerProps {
  platformName: string;
}

export function SyncLoadingSpinner({platformName, ...baseProps}: SyncLoadingSpinnerProps): ReactElement {
  const [showText, setShowText] = useState<boolean>(false);
  const motionProps = useMotionProps();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col h-full w-full items-center">
      <LoadingSpinner {...baseProps} />
      {showText && (
        <motion.div {...motionProps} key={showText ? "text" : "no-text"} className="flex flex-col items-center">
          <div className="flex flex-col items-center mt-6">
            <span>We are communicating with</span>
            <span>{platformName}.</span>
          </div>
          <div className="mt-6">
            <span>This may take several minutes.</span>
          </div>
          <div className="mt-6">
            <span className="font-bold">Do not close this tab.</span>
          </div>
        </motion.div>
      )}
    </div>
  );
}
