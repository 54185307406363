import {ErrorBoundary} from "@sentry/react";
import React, {ReactElement, Suspense} from "react";
import {useLocation} from "react-router-dom";
import {suspend} from "suspend-react";

import {getSyncResult} from "../../api/connectApi";
import useAsyncError from "../../hooks/useAsyncError";
import {SyncResult} from "../../interfaces";
import {LoadingSpinner} from "../Suspense/Loading";
import FetchAccountsSummary from "./FetchAccountsSummary";
import OngoingConnectivitySummary from "./OngoingConnectivitySummary";

interface SyncResultSummaryViewProps {
  syncResult: SyncResult;
  showOngoingConnectivitySummary?: boolean;
}

export function SyncResultSummaryView({
  syncResult,
  showOngoingConnectivitySummary = false,
}: SyncResultSummaryViewProps): ReactElement {
  return (
    <div className="flex flex-col w-full border border-navy-100 rounded p-2 md:p-4">
      <h2 className="mb-1 md:mb-2 font-bold text-navy-500 text-md md:text-xl">{syncResult.platform.name}</h2>
      <div>
        <div className="flex flex-col gap-y-2 md:gap-y-4">
          <FetchAccountsSummary syncResult={syncResult} />
          {showOngoingConnectivitySummary && <OngoingConnectivitySummary syncResult={syncResult} />}
        </div>
      </div>
    </div>
  );
}

interface SyncResultSummaryProps {
  syncTargetId: string;
}

// fetch and render responsibilities are split to enhance storyBook ergonomics
export function SyncResultSummary({syncTargetId}: SyncResultSummaryProps): ReactElement {
  const location = useLocation();
  const asyncThrow = useAsyncError();
  const syncResult =
    suspend(async () => {
      try {
        const syncresultResp = await getSyncResult(syncTargetId);
        return syncresultResp.payload;
      } catch (e) {
        asyncThrow(e);
      }
    }, [syncTargetId, location.pathname]) ?? null;

  if (!syncResult) return <></>;

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <SyncResultSummaryView
        syncResult={syncResult}
        showOngoingConnectivitySummary={location.pathname === "/summary"}
      />
    </div>
  );
}

export function SyncResultSummarySuspense(): ReactElement {
  // TODO: 2024-08-20 - BAS-1445 - Implement loading shimmers for <SyncResultSummaryBox/> loading states
  return (
    <div className="flex flex-col w-full border border-navy-100 rounded p-2 md:p-4 h-36 items-center justify-center">
      <LoadingSpinner />
    </div>
  );
}

interface SyncResultSummaryGroupProps {
  syncTargetIds: string[];
}

export function SyncResultSummaryGroup({syncTargetIds}: SyncResultSummaryGroupProps): ReactElement {
  return (
    <div className="flex flex-col justify-start items-center w-full h-full gap-y-4">
      {syncTargetIds.map((syncTargetId, index) => (
        <ErrorBoundary key={index}>
          <Suspense fallback={<SyncResultSummarySuspense />}>
            <SyncResultSummary syncTargetId={syncTargetId} />
          </Suspense>
        </ErrorBoundary>
      ))}
    </div>
  );
}
