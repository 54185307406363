import React from "react";

import {SHOW_SECURITY_PAGE} from "../constants/gatekeeper";

type AboutUsLink = Readonly<{
  title: string;
  icon: string;
  url: string;
}>;

const aboutUsLinks: ReadonlyArray<AboutUsLink> = [
  {
    title: "Terms of Service",
    icon: "icons/tos_icon.svg",
    url: "https://usebasis.co/",
  },
  {
    title: "Privacy Policy",
    icon: "icons/privacy_icon.svg",
    url: "https://usebasis.co/",
  },
  {
    title: "Security",
    icon: "icons/security_icon.svg",
    url: "https://usebasis.co/",
  },
  {
    title: "Contact Us",
    icon: "icons/contact_icon.svg",
    url: "mailto:connect-support@usebasis.co",
  },
];

export default function AboutUs(): React.ReactElement {
  return (
    <>
      <div className="mt-auto">
        <h4 className="text-xs font-bold text-navy-500 pb-4 uppercase">About Basis</h4>
        <ul className="mt-auto mb-4">
          {aboutUsLinks
            .filter((link) => {
              if (link.title === "Security" && !SHOW_SECURITY_PAGE) {
                return false;
              }
              return true;
            })
            .map((link) => (
              <li key={link.title} className="flex items-center mb-4">
                <img src={"/assets/sidebar/" + link.icon} alt={link.title} className="w-3 h-3 mr-1" />
                <a
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-navy-500 text-xs font-medium">
                  {link.title}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
