import {StatusCodes} from "http-status-codes";
import qs from "qs";

import BaseModel from "../models/base";

export class RequestError extends Error {
  constructor(message: string, public status: StatusCodes, public major: string, public minor: string) {
    super(message);
    this.name = "RequestError";
    this.status = status;
    this.major = major;
    this.minor = minor;
  }
}

async function handleResponseError(resp: Response): Promise<void> {
  if (!resp.ok) {
    const body = (await resp.json()) as BaseResponse;
    throw new RequestError(
      body.result.error?.description || "Unknown error",
      resp.status,
      body.result.error?.major || "",
      body.result.error?.minor || "",
    );
  }
}

export enum ClientErrorCodeMinor {
  PayloadInvalid = "payload-invalid",
  Unauthorized = "unauthorized",
  ChallengeExpired = "challenge-expired",
}

export interface RequestErrorBody {
  description: string;
  status: StatusCodes;
  major: string;
  minor: string;
}

export interface BaseResponse extends BaseModel {
  result: {
    success: boolean;
    error?: RequestErrorBody;
  };
  payload: unknown | null;
}

export async function APIPOSTRequest<T>(path: string, req: BaseModel = {}): Promise<T> {
  const resp = await fetch(`${process.env.REACT_APP_BASIS_API_URL ?? ""}/${path}`, {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    credentials: "include",
    body: BaseModel.serialize(req),
  });

  if (!resp.ok) {
    await handleResponseError(resp);
  }
  return BaseModel.deserialize<T>(await resp.text());
}

export async function APIGETRequest<T>(path: string, params: BaseModel = {}): Promise<T> {
  const query = qs.stringify(JSON.parse(BaseModel.serialize(params)) as object, {arrayFormat: "repeat"});
  const resp = await fetch(`${process.env.REACT_APP_BASIS_API_URL ?? ""}/${path}?${query}`, {
    method: "GET",
    headers: {"Content-Type": "application/json"},
    credentials: "include",
  });

  if (!resp.ok) {
    await handleResponseError(resp);
  }
  return BaseModel.deserialize<T>(await resp.text());
}

export async function APIDELETERequest<T>(path: string, params: BaseModel = {}): Promise<T> {
  const query = qs.stringify(JSON.parse(BaseModel.serialize(params)) as object, {arrayFormat: "repeat"});
  const resp = await fetch(`${process.env.REACT_APP_BASIS_API_URL ?? ""}/${path}?${query}`, {
    method: "DELETE",
    headers: {"Content-Type": "application/json"},
    credentials: "include",
  });

  if (!resp.ok) {
    await handleResponseError(resp);
  }
  return BaseModel.deserialize<T>(await resp.text());
}
