import {authorize} from "./api/connectApi";
import {InitToken, OAuthParams} from "./interfaces";
import BaseModel from "./models/base";

export function parseInitToken(initToken: string): InitToken {
  // convert to camel case
  const initTokenPayload = BaseModel.deserialize<InitToken>(atob(initToken.split(".")[1]));

  if (
    nullifyEmptyString(initTokenPayload.clientId) === null ||
    nullifyEmptyString(initTokenPayload.iat) === null ||
    nullifyEmptyString(initTokenPayload.exp) === null ||
    nullifyEmptyString(initTokenPayload.userEmail) === null ||
    nullifyEmptyString(initTokenPayload.companyName) === null
  ) {
    throw new Error("Missing required fields in init token");
  }

  const token: InitToken = {
    ...initTokenPayload,
    nativeUserId: nullifyEmptyString(initTokenPayload.nativeUserId),
    nativeCompanyId: nullifyEmptyString(initTokenPayload.nativeCompanyId),
    userName: nullifyEmptyString(initTokenPayload.userName),
    userPhone: nullifyEmptyString(initTokenPayload.userPhone),
    companyEin: nullifyEmptyString(initTokenPayload.companyEin),
  };

  return token;
}

export function getInitTokenMissingRequiredFields(initTokenParams: InitToken): string[] {
  const userFields: (keyof InitToken)[] = ["userName"];
  const missingFields = userFields.filter((field) => initTokenParams[field] === null);
  return missingFields;
}

export function parseAuthQueryParams(queryParams: URLSearchParams): OAuthParams {
  const client_id = queryParams.get("client_id");
  const scope = queryParams.get("scope");
  const redirect_uri = queryParams.get("redirect_uri");
  const state = queryParams.get("state");
  const code_challenge = queryParams.get("code_challenge");
  const init_token = queryParams.get("init_token");

  if (
    client_id === null ||
    scope === null ||
    redirect_uri === null ||
    state === null ||
    code_challenge === null ||
    init_token === null
  ) {
    throw new Error("Missing required query params");
  }

  const authParams: OAuthParams = {
    clientId: client_id,
    scope: scope,
    redirectUri: redirect_uri,
    state: state,
    codeChallenge: code_challenge,
    initToken: init_token,
  };

  return authParams;
}

export async function handleBackToClient(redirectUri: string, state: string): Promise<void> {
  try {
    const resp = await authorize();
    if (resp.result.success) {
      window.location.href = resp.payload.location;
    }
  } catch (e) {
    const errorParams = new URLSearchParams({
      error: "server_error",
      state: state,
    });
    window.location.href = `${redirectUri}?${errorParams.toString()}`;
  }
}

export function convertBase64SVGToImageUrl(base64String: string): string {
  return `data:image/svg+xml;base64,${base64String}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function containsNullOrEmptyProps(obj: Record<string, any>): boolean {
  return Object.values(obj).some((value) => {
    if (typeof value === "string") {
      return nullifyEmptyString(value) === null;
    }
    return value === null || value === undefined;
  });
}

export function isObjEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0;
}

export function parseFormData(formData: FormData): Readonly<{
  [key: string]: string;
}> {
  // TODO: 2024-08-27: don't assume the form inputs are strings
  return Object.fromEntries(Array.from(formData.entries()).map(([key, value]) => [key, String(value)])) as {
    [key: string]: string;
  };
}

export function secondsBetween(from: Date, to: Date): number {
  return (to.getTime() - from.getTime()) / 1000;
}

export function nullifyEmptyString(value: string | null | undefined): string | null {
  return value === "" || value == null ? null : value;
}
