import React, {ReactElement, useEffect, useState} from "react";

import {RequestError} from "../api/apiHandler";
import {getServiceLines, identify} from "../api/connectApi";
import {Button} from "../components/Buttons/Button";
import ContentHeader from "../components/ContentHeader";
import RequestErrorMessage from "../components/Exception/RequestErrorMessage";
import ServiceInfo from "../components/ServiceInfo";
import ServicesHintDiagram from "../components/ServicesHintDiagram";
import ValueProposition from "../components/ValueProposition";
import useAsyncError from "../hooks/useAsyncError";
import {useCopy} from "../hooks/useCopy";
import useSession from "../hooks/useSession";
import useStateNavigate from "../hooks/useStateNavigate";
import {ServiceLine} from "../interfaces";
import {getAuthenticationParams, setAuthenticationParams} from "../store/authSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {getUserParams, setProfile} from "../store/userSlice";
import TOS from "../TOS";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import {ContentFooter} from "./template/ContentFooter";

export default function LandingPage(): ReactElement {
  useSession();
  const [requestError, setRequestError] = useState<RequestError | null>(null);
  const asyncThrow = useAsyncError();
  const dispatch = useAppDispatch();
  const {navigateNext} = useStateNavigate();
  const {landHeader, landSubtitle, landBody, landServiceInfo, landCTA} = useCopy();
  const authenticationParams = useAppSelector(getAuthenticationParams);
  const {authFieldGaps} = useAppSelector(getUserParams);
  const {profile} = useAppSelector(getUserParams);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canNavigate, setCanNavigate] = useState<boolean>(false);

  useEffect(() => {
    if (canNavigate) navigateNext();
  }, [navigateNext, canNavigate, authenticationParams.emailChallenged, profile.verified]);

  const handleContinue = async () => {
    setRequestError(null);
    // TODO 2024-06-27: move this state navigation logic to useStateNavigate
    if (authFieldGaps.missingFields.length > 0) {
      navigateNext("/auth");
      return;
    }

    try {
      setIsLoading(true);
      const resp = await identify({});
      if (resp.result.success) {
        let serviceLines = [] as Array<ServiceLine>;
        if (resp.payload.isAuthenticated) {
          const serviceLinesResp = await getServiceLines();
          serviceLines = serviceLinesResp.payload.serviceLines;
        }
        dispatch(
          setProfile({
            ...profile,
            verified: resp.payload.isVerified,
            authorized: resp.payload.isAuthorized,
            serviceLines: serviceLines,
          }),
        );
        dispatch(
          setAuthenticationParams({
            ...authenticationParams,
            emailChallenged: true,
            emailAuthenticated: resp.payload.isAuthenticated,
          }),
        );
        setCanNavigate(true);
      }
    } catch (e) {
      if (e instanceof RequestError) {
        // TODO 2024-07-17 - BAS-1384 "Design and Implement Error States for Retryable Requests"
        // This will impact any component that stores a RequestError in state
        setRequestError(e);
      } else {
        asyncThrow(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title={landHeader} />
        <div className="mt-4 md:mt-10">
          <ValueProposition landSubtitle={landSubtitle} landBody={landBody} />
        </div>
        <div className="mt-10">
          <ServiceInfo content={landServiceInfo} />
        </div>
        <div className="mt-10 md:mb-6">
          <ServicesHintDiagram />
        </div>
      </ContentBody>
      <ContentFooter>
        <Button
          primary={true}
          fullWidth
          label={landCTA}
          onClick={() => {
            void handleContinue();
          }}
          loading={isLoading}
          disabled={isLoading}
        />
        {requestError && <RequestErrorMessage />}
        <TOS />
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
