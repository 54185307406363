import {CloudArrowUpIcon} from "@heroicons/react/24/outline";
import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {suspend} from "suspend-react";
import {v4 as uuidv4} from "uuid";

import {deleteResource, getResources, uploadResource} from "../../../api/connectApi";
import useAsyncError from "../../../hooks/useAsyncError";
import {FileTransferItem, Progress, Resource} from "../../../interfaces";
import {PromptResponseType} from "../../../models/prompt";
import {FileRow} from "./FileRow";

interface FileUploadProps {
  sessionId: string;
  promptId: string;
}

export function FileUpload({sessionId, promptId}: FileUploadProps): ReactElement {
  const [files, setFiles] = useState<FileTransferItem[]>([]);
  const xhrRefs = useRef<{[key: string]: XMLHttpRequest}>({});
  const asyncThrow = useAsyncError();

  const fileItems = useMemo((): FileTransferItem[] => {
    if (!sessionId || !promptId) return [];

    return suspend(async () => {
      try {
        const resp = await getResources(sessionId, promptId);
        return resp.payload.resources.map((resource: Resource) => ({
          file: {
            ...resource,
            fileId: uuidv4(),
          },
          isUploading: false,
          progress: {current: 100, total: 100},
        }));
      } catch (e) {
        asyncThrow(e);
      }
      return [];
    }, []);
  }, [sessionId, promptId, asyncThrow]);

  useEffect(() => {
    setFiles(fileItems);
    document.querySelector("form")?.dispatchEvent(new Event("input", {bubbles: true}));
  }, [fileItems]);

  function setInputValidity(input: HTMLInputElement, isValid: boolean): void {
    // manipulate input validity to disable/renable submit button when uploading
    input.setCustomValidity(isValid ? "" : "uploading");
    document.querySelector("form")?.dispatchEvent(new Event("input", {bubbles: true}));
  }

  async function uploadFiles(files: FileList): Promise<void> {
    const newFiles: FileTransferItem[] = Array.from(files).map((file) => ({
      file: {
        resourceId: "",
        promptId,
        mimeType: file.type,
        name: file.name,
        data: file,
        fileId: uuidv4(),
      },
      isUploading: true,
      progress: {current: 0, total: file.size},
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    const onUploadProgress = (fileId: string, progress: Progress) => {
      setFiles((prevFiles) => prevFiles.map((f) => (f.file?.fileId === fileId ? {...f, progress} : f)));
    };

    const uploadPromises = newFiles.map(async (fileItem) => {
      try {
        const file = fileItem.file;
        if (!file) throw new Error("file undefined");
        const response = await uploadResource(file, promptId, sessionId, xhrRefs, onUploadProgress);
        const updatedFile: FileTransferItem = {
          file: {
            ...file,
            resourceId: response.payload.resourceId,
          },
          isUploading: false,
          progress: {current: file?.data?.size ?? 0, total: file?.data?.size ?? 0},
        };
        setFiles((prevFiles) => prevFiles.map((f) => (f.file?.fileId === fileItem.file?.fileId ? updatedFile : f)));
      } catch (error) {
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file?.fileId === fileItem.file?.fileId ? {...f, isUploading: false, error: error as string} : f,
          ),
        );
      }
    });

    await Promise.all(uploadPromises);
  }

  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!event.target.files) return;
    setInputValidity(event.target, false);
    await uploadFiles(event.target.files);
    setInputValidity(event.target, true);
    event.target.value = "";
  }

  async function handleDrop(event: React.DragEvent<HTMLDivElement>): Promise<void> {
    event.preventDefault();
    const input = document.getElementById("file-upload") as HTMLInputElement;
    if (input) {
      setInputValidity(input, false);
      await uploadFiles(event.dataTransfer.files);
      setInputValidity(input, true);
    }
    event.dataTransfer.clearData();
  }

  function handleCancelUpload(fileId: string): void {
    xhrRefs.current[fileId]?.abort();
    delete xhrRefs.current[fileId];
    setFiles((prevFiles) => prevFiles.filter((f) => f.file?.fileId !== fileId));
  }

  async function handleRemoveFile(resourceId: string): Promise<void> {
    await deleteResource(sessionId, resourceId, promptId);
    setFiles((prevFiles) => prevFiles.filter((file) => file.file?.resourceId !== resourceId));
  }

  return (
    <div>
      <input
        type="file"
        className="hidden"
        id="file-upload"
        data-testid="file-upload"
        multiple
        onChange={(ev) => void handleFileUpload(ev)}
      />
      <label htmlFor="file-upload" className="text-sm text-gray-600 font-semibold">
        <div
          className="border-2 border-dashed border-gray-300 p-12 rounded-lg text-center cursor-pointer"
          onDragOver={(e) => e.preventDefault()}
          onDrop={(ev) => void handleDrop(ev)}>
          <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400 mb-2" />
          <span className="underline">Choose a file</span> or Drag and drop
          <p className="mt-2 text-sm text-gray-400">Supported file types: PDF, JPEG, PNG</p>
        </div>
      </label>
      <p className="mt-4 text-center text-xs text-gray-400">
        Uploaded documents are protected with end-to-end encryption
      </p>
      <div className="mt-4 space-y-4">
        {files.map((fileItem, index) => (
          <FileRow
            key={index}
            item={fileItem}
            handleRemoveFile={handleRemoveFile}
            handleCancelUpload={handleCancelUpload}
          />
        ))}
        <input
          type="hidden"
          name={promptId}
          value={JSON.stringify(files.map((f) => ({resourceId: f.file?.resourceId})))}
          data-response-type={PromptResponseType.Resource}
        />
      </div>
    </div>
  );
}
