import {BuildingLibraryIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";

import {convertBase64SVGToImageUrl} from "../utils";

type PlatformIconProps = Readonly<{
  icon: string | null;
}>;

export default function PlatformIcon({icon}: PlatformIconProps): ReactElement {
  return (
    <div className="flex items-center justify-center w-12 h-12 overflow-hidden">
      {icon ? (
        <img src={convertBase64SVGToImageUrl(icon)} alt="Platform logo" />
      ) : (
        <BuildingLibraryIcon className="p-1 border border-gray-300 " />
      )}
    </div>
  );
}
