export default class BaseModel {
  [key: string]: unknown;

  private static toCamelCase(s: string): string {
    return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
  }

  private static toSnakeCase(s: string): string {
    return s
      .replace(/([A-Z]+)([A-Z][a-z])/g, "$1_$2")
      .replace(/([a-z])([A-Z])/g, "$1_$2")
      .toLowerCase();
  }

  public static serialize(obj: BaseModel, keyFormat: "camel" | "snake" | "none" = "snake"): string {
    if (keyFormat === "camel") {
      return JSON.stringify(obj, (k, v) => this.camelCaseKeys(k, v));
    } else if (keyFormat === "snake") {
      return JSON.stringify(obj, (k, v) => this.snakeCaseKeys(k, v));
    } else {
      return JSON.stringify(obj);
    }
  }

  // Parameter T indicates return type
  public static deserialize<T>(data: string): T {
    return JSON.parse(data, (k, v) => this.camelCaseKeys(k, v)) as T;
  }

  private static camelCaseKeys(key: string, value: unknown): unknown {
    if (Array.isArray(value)) {
      return value.map((item) => this.camelCaseKeys(key, item));
    } else if (typeof value === "object" && value !== null) {
      const result: BaseModel = {};
      for (const objKey of Object.keys(value)) {
        const camelCaseKey = this.toCamelCase(objKey);
        result[camelCaseKey] = this.camelCaseKeys(camelCaseKey, value[objKey as keyof typeof value]);
      }
      return result;
    } else {
      return value;
    }
  }

  private static snakeCaseKeys(key: string, value: unknown): unknown {
    if (Array.isArray(value)) {
      return value.map((item) => this.snakeCaseKeys(key, item));
    } else if (typeof value === "object" && value !== null) {
      const result: BaseModel = {};
      for (const objKey of Object.keys(value)) {
        const snakeCaseKey = this.toSnakeCase(objKey);
        result[snakeCaseKey] = this.snakeCaseKeys(snakeCaseKey, value[objKey as keyof typeof value]);
      }
      return result;
    } else {
      return value;
    }
  }
}
