import * as Sentry from "@sentry/react";
import React from "react";

import SyncErrorScreen from "../components/Exception/SyncErrorScreen";
import SyncDriver from "../components/SyncMessages/SyncDriver";
import {WebSocketQueueProvider} from "../contexts/WebSocketQueueContext";
import {useAppSelector} from "../store/hooks";
import {getCurrentSyncSession} from "../store/syncSessionSlice";
import {UUID} from "../types";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";

export default function Sync() {
  const session = useAppSelector(getCurrentSyncSession);

  return (
    <AuthenticatedPageTemplate>
      <Sentry.ErrorBoundary fallback={<SyncErrorScreen />}>
        <WebSocketQueueProvider sessionId={session.sessionId as UUID}>
          <SyncDriver />
        </WebSocketQueueProvider>
      </Sentry.ErrorBoundary>
    </AuthenticatedPageTemplate>
  );
}
