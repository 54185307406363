import {useMemo} from "react";

import {transitionVariants} from "../constants/constants";
import {useAnimationState} from "../contexts/AnimationStateContext";

export default function useMotionProps() {
  const {updateIsAnimationComplete} = useAnimationState();
  return useMemo(
    () => ({
      initial: "hidden",
      animate: "visible",
      exit: "exit",
      variants: transitionVariants,
      onAnimationStart: () => updateIsAnimationComplete(false),
      onAnimationComplete: () => updateIsAnimationComplete(true),
    }),
    [updateIsAnimationComplete],
  );
}
