import "../../index.css";

import React, {ReactElement} from "react";

import {LoadingSpinner} from "../Suspense/Loading";

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  /**
   * Primary CTA button
   */
  primary?: boolean;
  /**
   * Disabled button
   */
  disabled?: boolean;
  /**
   * Loading
   */
  loading?: boolean;
  /**
   * Full width button
   */
  fullWidth?: boolean;
  /**
   * Button label
   */
  label: string;
  /**
   * Optional icon
   */
  icon?: ReactElement;
  /**
   * Specify custom color
   */
  styleOverride?: string | null;
  /**
   * Optional click handler
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Primary UI component for user interaction
 */
export function Button({
  primary = false,
  disabled = false,
  fullWidth = false,
  loading,
  label,
  icon,
  styleOverride = null,
  ...props
}: ButtonProps) {
  let classes =
    "flex justify-center items-center py-4 px-6 text-sm font-medium disabled:text-navy-200 disabled:cursor-not-allowed";

  if (styleOverride) {
    classes += ` ${styleOverride}`;
  } else {
    if (primary) {
      classes += " disabled:bg-green-100 disabled:shadow-md bg-green-500 text-navy-500";
    } else {
      classes += " bg-white text-navy-500 border-1 border-navy-100 disabled:border-navy-50";
    }
  }

  if (fullWidth) {
    classes += " w-full";
  }
  return (
    <button type="button" className={classes} disabled={disabled} {...props}>
      {loading ? (
        <LoadingSpinner size={6} highContrast />
      ) : (
        <div className={icon ? "flex flex-row items-center justify-center" : ""}>
          {icon && <div className="mr-2">{icon}</div>}
          <span>{label}</span>
        </div>
      )}
    </button>
  );
}
