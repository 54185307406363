import {AnimatePresence} from "framer-motion";
import React from "react";

import {AnimationStateProvider} from "../../contexts/AnimationStateContext";
import {useWebSocketQueue} from "../../contexts/WebSocketQueueContext";
import {SyncEventMessage} from "../../models/message";
import SyncViewController from "./SyncViewController";

export default function SyncDriver() {
  const {getCurrentMessage} = useWebSocketQueue();
  const currentMessage: SyncEventMessage | null = getCurrentMessage();

  return (
    <div className="flex flex-col w-full h-full">
      <AnimationStateProvider>
        <AnimatePresence mode="wait">
          <SyncViewController message={currentMessage} />
        </AnimatePresence>
      </AnimationStateProvider>
    </div>
  );
}
