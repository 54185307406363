import React, {ReactElement} from "react";

import {convertBase64SVGToImageUrl} from "../utils";

interface PlatformLogoProps {
  platformLogo: string;
}

export default function PlatformLogo({platformLogo}: PlatformLogoProps): ReactElement {
  return (
    <div className="flex items-center justify-center w-96 h-24 pb-2">
      <img
        className="max-w-full max-h-full w-auto h-auto object-contain"
        src={convertBase64SVGToImageUrl(platformLogo)}
        alt="Platform logo"
      />
    </div>
  );
}

interface PaddedPlatformLogoProps {
  logo: string | null;
  displayName: string;
}

export function PaddedPlatformLogo({logo, displayName}: PaddedPlatformLogoProps): ReactElement {
  return (
    <div className="flex w-full mt-6 py-6 md:mt-8 justify-center">
      {logo ? (
        <PlatformLogo platformLogo={logo} />
      ) : (
        <span className="text-lg font-medium text-navy-500">{displayName}</span>
      )}
    </div>
  );
}
