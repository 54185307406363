import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {SyncSessionParams} from "../interfaces";
import {SyncError} from "../models/error";
import {RootState} from ".";

export interface SyncSessionHistory {
  [sessionId: string]: SyncSessionParams;
}

export interface SyncSessionsContainer {
  currentSessionId: string;
  sessions: SyncSessionHistory;
}

const initialState: SyncSessionsContainer = {
  currentSessionId: "",
  sessions: {},
};

export const syncSessionSlice = createSlice({
  name: "sync",
  initialState,
  reducers: {
    createSyncSession: (state, action: PayloadAction<SyncSessionParams>) => {
      const sessionId = action.payload.sessionId;
      state.currentSessionId = sessionId;
      state.sessions[sessionId] = action.payload;
    },
    deleteSyncSession: (state, action: PayloadAction<string>) => {
      delete state.sessions[action.payload];
    },
    setError: (state, action: PayloadAction<{sessionId: string; error: SyncError | null}>) => {
      state.sessions[action.payload.sessionId].error = action.payload.error;
    },
    setTerminalError: (state, action: PayloadAction<{sessionId: string; terminalError: SyncError | null}>) => {
      state.sessions[action.payload.sessionId].terminalError = action.payload.terminalError;
    },
  },
});

export const {createSyncSession, deleteSyncSession, setError, setTerminalError} = syncSessionSlice.actions;

export const getCurrentSyncSession = (state: RootState): SyncSessionParams => {
  const sessionId = state.sync.currentSessionId;
  return state.sync.sessions[sessionId];
};

export const getSyncSessionById = (state: RootState, sessionId: string): SyncSessionParams => {
  return state.sync.sessions[sessionId];
};

export const getSyncSessions = (state: RootState): SyncSessionHistory => state.sync.sessions;

export const getError = (state: RootState, params?: {sessionId?: string}): SyncError | null => {
  const sessionId = params?.sessionId ?? state.sync.currentSessionId;
  if (sessionId in state.sync.sessions) return state.sync.sessions[sessionId].error;
  return null;
};

export const getTerminalError = (state: RootState, params?: {sessionId?: string}): SyncError | null => {
  const sessionId = params?.sessionId ?? state.sync.currentSessionId;
  if (sessionId in state.sync.sessions) return state.sync.sessions[sessionId].terminalError;
  return null;
};

export default syncSessionSlice.reducer;
