import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";

import PageTemplate from "../../pages/template/PageTemplate";
import {getHasInitialized} from "../../store/authSlice";
import {getClientConfig} from "../../store/clientConfigSlice";
import {useAppSelector} from "../../store/hooks";
import ContentHeader from "../ContentHeader";
import DesktopMinimalSidebar from "../Layout/DesktopMinimalSidebar";
import HeaderBar from "../Layout/HeaderBar";

export function GeneralErrorStatement(): ReactElement {
  const {customerShortName} = useAppSelector(getClientConfig);

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-row items-center">
        <ExclamationCircleIcon className="h-10 w-10 mr-4 fill-red-500 stroke-white" />
        <ContentHeader title="Something went wrong." />
      </div>
      <div className="text-xl">{"An unexpected error occurred or the URL used to get to this page is not valid."}</div>
      <div>
        {`If you are attempting to connect your accounts to ${customerShortName}, please visit their website and try again.`}
      </div>
    </div>
  );
}

export default function AppErrorFallback(): ReactElement {
  const hasInitialized = useAppSelector(getHasInitialized);

  return (
    <div className="flex flex-col h-full w-full">
      {
        // Cannot display unless clientConfig is available in store
        hasInitialized && (
          <div className="flex-none w-full">
            <HeaderBar />
          </div>
        )
      }
      <div className="grow w-full min-h-0 h-full">
        <div className="flex w-full h-full">
          <div className="max-md:hidden relative flex w-auto">
            <DesktopMinimalSidebar shouldDisplayLogo={false} />
          </div>
          <div className="flex flex-col h-full w-full items-center">
            <PageTemplate>
              <GeneralErrorStatement />
            </PageTemplate>
          </div>
        </div>
      </div>
    </div>
  );
}
