import BaseModel from "./base";

export enum PromptType {
  Button = "button",
  Numeric = "numeric",
  Text = "text",
  Password = "password",
  SingleOption = "single-option",
  MultiOption = "multi-option",
  File = "file",
  Files = "files",
}

export interface PromptOption extends BaseModel {
  label: string;
  value: string;
  hint: string | null;
}

export interface Prompt extends BaseModel {
  promptId: string;
  label: string;
  promptType: PromptType;
  length: [number | null, number | null]; // [min, max]
  options: Array<PromptOption> | null;
  defaultResponse: string | null;
  assets: Readonly<{
    [key: string]: string;
  }> | null;
}

export enum PromptResponseType {
  Scalar = "scalar",
  Vector = "vector",
  Resource = "resource",
}

export interface PromptResponse<T> extends BaseModel {
  promptId: string;
  responseType: PromptResponseType;
  value: T;
}

export interface ResourceResponse extends BaseModel {
  resourceId: string;
}

export type ScalarPromptResponse = PromptResponse<string>;
export type VectorPromptResponse = PromptResponse<string[]>;
export type ResourcePromptResponse = PromptResponse<ResourceResponse[]>;

export type AnyPromptResponse = ScalarPromptResponse | VectorPromptResponse | ResourcePromptResponse;
