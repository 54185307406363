import "../index.css";

import React, {InputHTMLAttributes, ReactElement, useState} from "react";

import {PromptOption} from "../models/prompt";

interface SingleOptionProps extends InputHTMLAttributes<HTMLInputElement> {
  promptId: string;
  options: PromptOption[];
}

/**
 * Single Option component
 */
export function SingleOption({promptId, options, ...props}: SingleOptionProps): ReactElement {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <>
      {options.map((option: PromptOption, i) => {
        const key = `${option.label}-${i}`;
        const isSelected = selectedOption === option.value;
        return (
          <label
            htmlFor={key}
            key={key}
            className={`flex p-4 mb-4 border ${isSelected ? "border-green-500 border-1" : "border-gray-300"}`}>
            <div className="flex items-top h-12 pt-1">
              <input
                name={promptId}
                id={key}
                type="radio"
                value={option.value}
                checked={isSelected}
                onChange={() => handleChange(option.value)}
                className="appearance-none h-4 w-4 rounded-full border-2 border-gray-300 bg-white checked:border-5 checked:border-green-500 focus:outline-none transition duration-200 relative before:absolute before:content-[''] before:bg-white before:rounded-full before:w-0.5 before:h-0.5 before:opacity-0 checked:before:opacity-100 before:transform before:-translate-x-1/2 before:-translate-y-1/2 before:top-1/2 before:left-1/2"
                {...props}
              />
            </div>
            <div className="ml-4 text-sm">
              <p className="text-base text-blue-900 font-medium mb-1">{option.label}</p>
              <p className="text-sm text-gray-700">{option.hint}</p>
            </div>
          </label>
        );
      })}
    </>
  );
}
