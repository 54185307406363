import {useSearchParams} from "react-router-dom";
import {suspend} from "suspend-react";

import {initialize} from "../api/connectApi";
import {Profile} from "../interfaces";
import {getHasInitialized, setHasInitialized, setOAuthParams} from "../store/authSlice";
import {setClientConfig} from "../store/clientConfigSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {setMissingFields, setProfile} from "../store/userSlice";
import {getInitTokenMissingRequiredFields, parseAuthQueryParams, parseInitToken} from "../utils";

export default function useSession(): void {
  const dispatch = useAppDispatch();
  const [queryParams] = useSearchParams();
  const hasInitialized = useAppSelector(getHasInitialized);

  if (!hasInitialized) {
    const oauthParams = parseAuthQueryParams(queryParams);
    dispatch(setOAuthParams(oauthParams));
    const initTokenParams = parseInitToken(oauthParams.initToken);

    const missingFields = getInitTokenMissingRequiredFields(initTokenParams);
    dispatch(setMissingFields(missingFields));

    suspend(async () => {
      const resp = await initialize({...oauthParams});
      if (resp?.result.success) {
        dispatch(setHasInitialized(true));
        const {clientConfig} = resp.payload;
        const profile: Profile = {
          company: {
            ein: initTokenParams.companyEin,
            name: initTokenParams.companyName,
          },
          user: {
            emailAddress: initTokenParams.userEmail,
            name: initTokenParams.userName,
          },
          serviceLines: [],
          verified: false,
          authorized: false,
        };
        dispatch(setClientConfig(clientConfig));
        dispatch(setProfile(profile));
      }
    }, []);
  }
}
