import {useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {ENTRY_PATH} from "../constants/constants";
import {getAuthenticationParams} from "../store/authSlice";
import {getInitTokenParams} from "../store/authSlice";
import {useAppSelector} from "../store/hooks";
import {getCurrentSyncSession} from "../store/syncSessionSlice";
import {getUserParams} from "../store/userSlice";

const useStateNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authParams = useAppSelector(getAuthenticationParams);
  const initTokenParams = useAppSelector(getInitTokenParams);
  const {authFieldGaps, profile} = useAppSelector(getUserParams);
  const platformId = useAppSelector(getCurrentSyncSession)?.platformId ?? "";

  // TODO: 2024-08-28 - useStateNavigate to define navigation actions via flowdown based on current location
  // AKA more of a well defined state machine.
  const navigateNext = useCallback(
    (path?: string) => {
      if (path) {
        navigate(path);
      } else if (
        authParams.emailAuthenticated &&
        initTokenParams.platformId === null &&
        profile.serviceLines.length > 0
      ) {
        navigate("/connected");
      } else if (authParams.emailAuthenticated) {
        navigate(initTokenParams.platformId !== null ? `/platforms/${initTokenParams.platformId}` : "/platforms");
      } else if (!authParams.emailAuthenticated && authParams.emailChallenged) {
        navigate("/verify");
      } else if (authFieldGaps.missingFields.length > 0) {
        navigate("/auth");
      }
    },
    [
      authFieldGaps.missingFields.length,
      authParams.emailAuthenticated,
      authParams.emailChallenged,
      initTokenParams.platformId,
      navigate,
      profile.serviceLines.length,
    ],
  );

  const navigateBack = useCallback(
    (count?: number) => {
      // temporary hack to fix stateful back navigation
      if (location.pathname === "/sync") {
        platformId !== "" ? navigate(`/platforms/${platformId}`) : navigate("/platforms");
      } else if (
        authParams.emailAuthenticated &&
        profile.verified &&
        location.pathname === "/platforms" &&
        profile.serviceLines.length === 0
      ) {
        navigate(ENTRY_PATH);
      } else if (!authParams.emailAuthenticated && authParams.emailChallenged) {
        navigate(ENTRY_PATH);
      } else if (authFieldGaps.missingFields.length > 0) {
        navigate(ENTRY_PATH);
      } else if (count) {
        navigate(count);
      } else {
        navigate(-1);
      }
    },
    [
      authFieldGaps.missingFields.length,
      authParams.emailAuthenticated,
      authParams.emailChallenged,
      location.pathname,
      navigate,
      platformId,
      profile.serviceLines.length,
      profile.verified,
    ],
  );

  return {
    navigateNext,
    navigateBack,
  };
};

export default useStateNavigation;
