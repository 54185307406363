import React, {ReactElement} from "react";

import {defaultErrorMessage} from "../../constants/constants";

export default function RequestErrorMessage(): ReactElement {
  return (
    <p role="alert" className="text-xs font-medium text-orange-500">
      {defaultErrorMessage}
    </p>
  );
}
