import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import React from "react";

export interface goBackButtonProps {
  onClick: () => void;
}

export default function GoBackButton({onClick}: goBackButtonProps) {
  return (
    <button className="flex flex-row items-center" onClick={onClick}>
      <ArrowLeftIcon className="h-6 w-6 stroke-navy-500" />
      <span className="max-md:hidden ml-2 md:ml-1 text-gray-900 text-lg">Go back</span>
    </button>
  );
}
