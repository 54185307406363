import "../index.css";

import React, {ChangeEvent, InputHTMLAttributes, ReactElement, useState} from "react";

interface FormTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  /**
   * Full Width Form Text Field
   */
  fullWidth?: boolean;
  /**
   * Form Field Label
   */
  label: string;
  /**
   * Form Field Text Placeholder
   */
  defaultValue?: string;
  /**
   * Reason for disabled setting
   */
  disabledText?: string;
  /**
   * Input validation pattern
   */
  pattern?: string;
  /**
   * Explanation for input validation error
   */
  errorText?: string;
  /**
   * Optional onChange handler
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optional onBlur handler
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional onFocus handler
   */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

/**
 * Form Text Field component
 */
export function FormTextField({
  fullWidth = false,
  name,
  label,
  defaultValue,
  disabledText,
  pattern,
  errorText,
  onChange,
  onBlur,
  onFocus,
  ...props
}: FormTextFieldProps): ReactElement {
  const [value, setValue] = useState(defaultValue || "");
  const [isValid, setIsValid] = useState(true);

  const inputStyle = `
    border-b-2
    border-navy-100
    focus:border-green-500
    invalid:border-red-500
    focus:invalid:border-red-500
    placeholder-shown:invalid:border-navy-100
    placeholder-shown:focus:invalid:border-navy-100
    py-2 px-2
    text-navy-500
    leading-tight
    focus:outline-none
    focus:shadow-outline
    read-only:cursor-not-allowed
    ${fullWidth ? "w-full" : ""}
    bg-transparent
    text-indent: 0.5em
  `;

  const labelOverheadStyle = `
    text-navy-300
    absolute
    left-2
    transition-all
    text-sm top-0
    transform -translate-y-3
  `;

  const labelPlaceholderStyle = `
    pointer-events-none
    text-navy-300
    absolute
    left-2
    transition-all
    text-base top-2
  `;

  const disabledTextStyle = `
    text-navy-300 
    text-xs 
    absolute 
    right-3 
    bottom-0 
    py-2
  `;

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (pattern && event.target.value !== "") setIsValid(event.target.checkValidity());
    else setIsValid(true);
    if (onChange) onChange(event);
  };

  return (
    <div className="relative w-full">
      <label className={value ? labelOverheadStyle : labelPlaceholderStyle}>{label}</label>
      <div>
        <input
          name={name}
          className={inputStyle}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          pattern={pattern}
          aria-invalid={!isValid}
          aria-errormessage={`${name}-error`}
          placeholder=" "
          {...props}
        />
        {disabledText && <span className={disabledTextStyle}>{disabledText}</span>}
      </div>
      {!isValid && errorText && (
        <p id={`${name}-error`} role="alert" className="mt-2 text-sm text-orange-500">
          {errorText}
        </p>
      )}
    </div>
  );
}
