import React, {ReactElement} from "react";

import {convertBase64SVGToImageUrl} from "../utils";

interface CustomerLogoProps {
  customerLogo: string | null;
}

export default function CustomerLogo(props: CustomerLogoProps): ReactElement {
  const {customerLogo = null} = props;

  return (
    <div className="origin-top-left w-full h-auto">
      {customerLogo && (
        <img className="w-full h-auto" src={convertBase64SVGToImageUrl(customerLogo)} alt="Client logo" />
      )}
    </div>
  );
}
