import {InformationCircleIcon} from "@heroicons/react/24/outline";
import React, {ReactElement} from "react";

interface BrandTagProps {
  showInfoIcon: boolean;
}

export default function BrandTag({showInfoIcon}: BrandTagProps): ReactElement {
  return (
    <div className="flex flex-row items-center gap-x-2 mb-12">
      <span className="text-xs md:text-md text-navy-400">Powered by</span>
      <img className="h-3 md:h-4" src="/assets/basis/basis_logo.svg" alt="basis logo"></img>
      <div className="flex md:hidden">
        {showInfoIcon && <InformationCircleIcon className="h-4 w-4 -ml-1 stroke-navy-400" />}
      </div>
    </div>
  );
}
