// never sent over the wire, informs the UI only
export enum SubuserChoiceStatus {
  Advisory = "advisory",
  Start = "start",
  OptedIn = "opt-in",
  OptedOut = "opt-out",
}

export enum SubuserStrategy {
  Manual = "manual",
  Automated = "automated",
  Unsupported = "unsupported",
  Unspecified = "unspecified",
}
