import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {Profile} from "../interfaces";
import {RootState} from "./index";

const initialProfile: Profile = {
  company: {
    ein: null,
    name: "",
  },
  user: {
    emailAddress: "",
    name: null,
  },
  serviceLines: [],
  verified: false,
  authorized: false,
};

const initialAuthfieldGaps = {
  missingFields: [] as string[],
};

const initialState = {
  profile: initialProfile,
  authFieldGaps: initialAuthfieldGaps,
};

export const userParamsSlice = createSlice({
  name: "userParams",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<typeof initialState>) => {
      state = action.payload;
      return state;
    },
    setProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload;
    },
    setMissingFields: (state, action: PayloadAction<string[]>) => {
      state.authFieldGaps.missingFields = action.payload;
    },
  },
});

export const {setProfile, setMissingFields} = userParamsSlice.actions;

export const getUserParams = (state: RootState) => state.user;

export default userParamsSlice.reducer;
