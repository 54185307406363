import React, {ReactElement, ReactNode, createContext, useContext, useState} from "react";

type AnimationStateProps = {
  isAnimationComplete?: boolean;
  updateIsAnimationComplete?: (isAnimationComplete: boolean) => void;
};

type AnimationStateExportProps = {
  [K in keyof AnimationStateProps]-?: AnimationStateProps[K];
};

const AnimationStateMutatorContext = createContext<AnimationStateProps>({});

function AnimationStateProvider({children}: {children: ReactNode}): ReactElement {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  function updateIsAnimationComplete(newAnimationCompleteState: boolean) {
    if (newAnimationCompleteState !== isAnimationComplete) {
      setIsAnimationComplete(newAnimationCompleteState);
    }
  }

  return (
    <AnimationStateMutatorContext.Provider value={{isAnimationComplete, updateIsAnimationComplete}}>
      {children}
    </AnimationStateMutatorContext.Provider>
  );
}

function useAnimationState(): AnimationStateExportProps {
  const context = useContext(AnimationStateMutatorContext);
  const {isAnimationComplete, updateIsAnimationComplete} = context;

  if (isAnimationComplete == null || !updateIsAnimationComplete == null) {
    throw new Error("useAnimationState must be used within a AnimationStateProvider");
  }
  return context as AnimationStateExportProps;
}

export {AnimationStateProvider, useAnimationState};
