import {useEffect} from "react";
import {useLocation, useSearchParams} from "react-router-dom";

import {ENTRY_PATH} from "../constants/constants";
import {getHasInitialized} from "../store/authSlice";
import {useAppSelector} from "../store/hooks";

export default function useDetectInvalidPageAccess(): void {
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const hasInitialized = useAppSelector(getHasInitialized);

  const enteredWithoutOauthParams = location.pathname === ENTRY_PATH && Array.from(queryParams).length === 0;

  useEffect(() => {
    if (!hasInitialized) {
      if (location.pathname !== ENTRY_PATH || enteredWithoutOauthParams) {
        throw new Error("Session state is invalid - entered app without initializing session");
      }
    }
  }, [hasInitialized, enteredWithoutOauthParams, location.pathname]);
}
